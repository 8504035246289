<template>
    <div class="columns">
        <div class="column is-three-fifths">
            <div class="columns">
                <div class="column is-6">
                    <div class="field">
                        <label class="label">Via</label>
                        <div class="control">
                            <v-select
                                :filterable="false"
                                :options="carrers"
                                @search="trobarCarrer"
                                v-model="selectedCarrer"
                                @option:selected="optionSelected"
                            >
                                <template v-slot:no-options>
                                    Escriu el nom de la via
                                </template>
                                <template v-slot:option="option">
                                    <div class="d-center">
                                        {{ option.label }}
                                    </div>
                                </template>
                                <template v-slot:selected-option="option">
                                    <div class="selected d-center">
                                        {{ option.label }}
                                    </div>
                                </template>
                            </v-select>
                        </div>
                    </div>
                </div>
                <div class="column is-4">
                    <div class="field">
                        <label class="label">N&uacute;mero</label>
                        <div class="control">
                            <v-select
                                :filterable="false"
                                :searchable="false"
                                :options="numeros"
                                v-model="selectedNumero"
                                @option:selected="optionSelectedNumero"
                            >
                                <template v-slot:no-options>
                                    Escriu el n&uacute;mero
                                </template>
                                <template v-slot:option="option">
                                    <div class="d-center">
                                        {{ option.label }}
                                    </div>
                                </template>
                                <template v-slot:selected-option="option">
                                    <div class="selected d-center">
                                        {{ option.label }}
                                    </div>
                                </template>
                            </v-select>
                        </div>
                    </div>
                </div>
                <div class="column">
                    <div class="field">
                        <label class="label">&nbsp;</label>
                        <div class="control">
                            <button class="button" v-on:click="enviarAdreca">Envia</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="column">
            <div class="columns">
                <div class="column is-two-thirds">
                    <div class="field">
                        <label class="label">Refer&egrave;ncia cadastral</label>
                        <div class="control">
                            <v-select
                                :filterable="false"
                                :options="refCats"
                                @search="trobarRefcat"
                                v-model="refcatTrobada"
                            >
                                <template v-slot:no-options>
                                    Escriu la refer&egrave;ncia cadastral
                                </template>
                                <template v-slot:option="option">
                                    <div class="d-center">
                                        {{ option.label }}
                                    </div>
                                </template>
                                <template v-slot:selected-option="option">
                                    <div class="selected d-center">
                                        {{ option.label }}
                                    </div>
                                </template>
                            </v-select>
                        </div>
                    </div>
                </div>
                <div class="column">
                    <div class="field">
                        <label class="label">&nbsp;</label>
                        <div class="control">
                            <button class="button" v-on:click="enviarRefcat">Envia</button>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>

</template>

<script>
import Axios from 'axios'
import vSelect from 'vue-select'

export default {
    name: "Formulari",
    components: {
        vSelect,
    },
    directives: {},
    data: function(){
        return {
            selectedCarrer: [],
            carrers: [],
            numeros: [],
            selectedNumero: [],
            selectedRefcat: '',
            refCats: [],
            refcatTrobada: '',
            a: '',
        }
    },
    props: [],
    computed: {},
    watch: {},
    methods: {
        trobarCarrer (query, loading) {
            let self = this;
            if (query.length > 2) {
                loading(true);
                Axios.get(process.env.VUE_APP_URL_PHP + 'AJAXTrobarCarrer.php?carrer=' + query)
                    .then(function (response) {
                        console.log(response.data);
                        self.carrers = response.data;
                    })
                    .catch(function (error) {
                        console.log("ERROR", error);
                    })
                    .finally(function () {
                        console.log("ALWAYS");
                        loading(false);
                    });
            }
        },
        trobarRefcat(query, loading){
            let self = this;
            if (query.length > 2){
                loading(true);
                Axios.get(process.env.VUE_APP_URL_PHP + 'AJAXTrobarAmbRefcat.php?refcat=' + query)
                    .then(function (response) {
                        console.log(response.data);
                        self.refCats = response.data;
                    })
                    .catch(function (error) {
                        console.log("ERROR", error);
                    })
                    .finally(function () {
                        console.log("ALWAYS");
                        loading(false);
                    });
            }
        },
        enviarRefcat(){
            console.log("SELECTED REFCAT", this.refcatTrobada);
            this.$emit('trobat', this.refcatTrobada);

            Axios.get(process.env.VUE_APP_URL_PHP + 'saveSearch.php?refcat=' + this.refcatTrobada)
                .then(function(response){
                    console.log("RESPONSE SAVE SEARCH:", response);
                })
                .catch(function (error) {
                    console.log("ERROR", error);
                })
                .finally(function () {
                    console.log("ALWAYS");
                });

        },
        optionSelected(selectedOption){
            console.log("SELECTED", selectedOption);

            let self = this;
            Axios.get(process.env.VUE_APP_URL_PHP + 'AJAXTrobarNumero.php?carrer=' + this.selectedCarrer)
                .then(function (response) {
                    console.log(response.data);
                    self.numeros = response.data;
                    var collator = new Intl.Collator(undefined, {numeric: true, sensitivity: 'base'});
                    self.numeros.sort(collator.compare);
                })
                .catch(function (error) {
                    console.log("ERROR", error);
                })
                .finally(function () {
                    console.log("ALWAYS");
                });
        },
        optionSelectedNumero(selectedOption) {
            console.log("SELECTED NUMERO", selectedOption);
            this.selectedNumero = selectedOption;
        },
        enviarAdreca(){
            let self = this;
            Axios.get(process.env.VUE_APP_URL_PHP + 'AJAXTrobarRefcat.php?carrer=' + this.selectedCarrer + "&numero=" + this.selectedNumero)
                .then(function (response) {
                    console.log(response.data);
                    self.refcatTrobada = response.data[0];
                   // self.getInfoByRefcat();
                    self.$emit('trobat', self.refcatTrobada);

                    Axios.get(process.env.VUE_APP_URL_PHP + 'saveSearch.php?refcat=' + response.data[0])
                        .then(function(response){
                            console.log("RESPONSE SAVE SEARCH:", response);
                    });
                })
                .catch(function (error) {
                    console.log("ERROR", error);
                })
                .finally(function () {
                    console.log("ALWAYS");
                });
        },

    },
    created() {},
    destroyed() {},
    mounted: function () {
        console.log("HOLA FORMULARI!!");
    },
    beforeDestroy() {}
}
</script>

<style lang="scss">

@import 'vue-select/dist/vue-select.css';
@import './scss/mystyles.scss';

</style>
