<template>
    <div>
        <section>
            <figure class="image">
                <img style="width: auto;height: 47px;" src="./assets/header.png">
            </figure>
        </section>
        <section>
            <div class="titolot has-text-centered">Simulador de rehabilitaci&oacute;</div>
        </section>
        <section class="form">
            <Formulari v-on:trobat="getInfoByRefcat"></Formulari>
        </section>

        <div v-if="isVisibleAvisNoDades">
        <section>
            <div class="columns">
                <div class="column is-10 is-offset-1 has-text-centered has-text-weight-bold notfound">Aquest simulador no disposa d&rsquo;informaci&oacute; sobre l&rsquo;edifici seleccionat. Per a m&eacute;s informaci&oacute;, pots consultar el <a href="./Simulador_Metodologia.pdf" target="_blank">document metodol&ograve;gic</a> i/o contactar amb el teu administrador de finques o amb l&rsquo;Oficina de Rehabilitaci&oacute; Municipal de l&rsquo;Ajuntament de Barcelona a trav&eacute;s del correu <span class="correu">ng.rehabilitacio@bcn.cat.</span></div>
            </div>
        </section>
        </div>

        <div v-else>
        <section>
            <div class="titolet">Quin &eacute;s l&rsquo;estat actual del teu edifici?</div>
            <div>Per con&egrave;ixer l&rsquo;estat energ&egrave;tic actual del teu edifici, aquesta aplicaci&oacute; et presenta a continuaci&oacute; una taula resum amb les dades estimades m&eacute;s rellevants similars a les oferides en un Certificat d&rsquo;Efici&egrave;ncia Energ&egrave;tica (CEE) oficial</div>
            <div class="columns" style="margin-top: 10px;">
                <div class="column is-10 is-offset-1">
                    <div class="is-flex is-align-content-baseline puntejat">
                        <div>Refer&egrave;ncia cadastral</div>
                        <span class="is-flex-grow-1 is-flex-shrink-1"></span>
                        <div>{{ refcatTrobada }}</div>
                    </div>
                    <div class="is-flex is-align-content-baseline puntejat">
                        <div>N&ordm; d'habitatges</div>
                        <span class="is-flex-grow-1 is-flex-shrink-1"></span>
                        <div>{{ dadesEA.N_HAB }}</div>
                    </div>
                    <div class="is-flex is-align-content-baseline puntejat">
                        <div>Any de construcci&oacute;</div>
                        <span class="is-flex-grow-1 is-flex-shrink-1"></span>
                        <div>{{ dadesEA.EF_AR_01 }}</div>
                    </div>
                    <div class="is-flex is-align-content-baseline puntejat">
                        <div>L&#39;edifici ha passat la Inspecci&oacute; T&egrave;cnica d&#39;Edficis (ITE)</div>
                        <span class="is-flex-grow-1 is-flex-shrink-1"></span>
                        <div>{{ (dadesEA.ITE == 0) ? 'No' : 'S&iacute;' }}</div>
                    </div>
                    <div class="is-flex is-align-content-baseline puntejat">
                        <div>L&#39;edifici disposa de certificat d&#39;efici&egrave;ncia energ&egrave;tica (CEE)</div>
                        <span class="is-flex-grow-1 is-flex-shrink-1"></span>
                        <div>{{  (dadesEA.CE == 0) ? 'No' : 'S&iacute;' }}</div>
                    </div>
                    <div class="is-flex is-align-content-baseline puntejat">
                        <div>Qualificaci&oacute; energ&egrave;tica estimada de l&rsquo;edifici</div>
                        <span class="is-flex-grow-1 is-flex-shrink-1"></span>
                        <div>
                            <span>{{ dadesEA.EF_EN_26 }}</span>
                            <div class="is-pulled-right">
                                <popper
                                    trigger="hover"
                                    :options="{
                                      placement: 'top',
                                      modifiers: { offset: { offset: '0,10px' } }
                                    }">
                                    <div class="popper">La qualificaci&oacute; energ&egrave;tica de l&rsquo;edifici en emissions &eacute;s un valor que indica la quantitat de di&ograve;xid de carboni (CO2) alliberat a l&rsquo;atmosfera a causa del consum d&rsquo;energia vinculat als sistemes de calefacci&oacute;, refrigeraci&oacute; i de producci&oacute; d&rsquo;aigua calenta sanit&agrave;ria (ACS) de l&rsquo;immoble. Aquest valor es classifica en 7 etiquetes ordenades de la &ldquo;A&rdquo; (situaci&oacute; m&eacute;s favorable) a la &ldquo;G&rdquo; (situaci&oacute; m&eacute;s desfavorable)</div>
                                    <img  slot="reference" style="width: 12px;margin-left: 5px;margin-top: 3px;" src="@/assets/info.svg"/>
                                </popper>
                            </div>
                        </div>
                    </div>
                    <div class="is-flex is-align-content-baseline puntejat">
                        <div>Qualificaci&oacute; energ&egrave;tica estimada en consum</div>
                        <span class="is-flex-grow-1 is-flex-shrink-1"></span>
                        <div>
                            <span>{{ dadesEA.EF_EN_22 }}</span>
                            <div class="is-pulled-right">
                                <popper
                                    trigger="hover"
                                    :options="{
                                      placement: 'top',
                                      modifiers: { offset: { offset: '0,10px' } }
                                    }">
                                    <div class="popper">La qualificaci&oacute; energ&egrave;tica de l&rsquo;edifici en consum &eacute;s un valor que indica la quantitat d&rsquo;energia necess&agrave;ria per satisfer el consum d&rsquo;energia dels sistemes de calefacci&oacute;, refrigeraci&oacute; i de producci&oacute; d&rsquo;aigua calenta sanit&agrave;ria (ACS) de l&rsquo;immoble segons la seva demanda. Aquest valor es classifica en 7 etiquetes ordenades de la &ldquo;A&rdquo; (situaci&oacute; m&eacute;s favorable) a la &ldquo;G&rdquo; (situaci&oacute; m&eacute;s desfavorable)</div>
                                    <img  slot="reference" style="width: 12px;margin-left: 5px;margin-top: 3px;" src="@/assets/info.svg"/>
                                </popper>
                            </div>
                        </div>
                    </div>
                    <div class="is-flex is-align-content-baseline puntejat">
                        <div>Qualificaci&oacute; estimada de la demanda de calefacci&oacute;</div>
                        <span class="is-flex-grow-1 is-flex-shrink-1"></span>
                        <div>
                            <span>{{ dadesEA.EF_EN_07 }}</span>
                            <div class="is-pulled-right">
                                <popper
                                    trigger="hover"
                                    :options="{
                                      placement: 'top',
                                      modifiers: { offset: { offset: '0,10px' } }
                                    }">
                                    <div class="popper">La qualificaci&oacute; de la demanda energ&egrave;tica de calefacci&oacute; &eacute;s un valor que indica la quantitat d&rsquo;energia necess&agrave;ria per mantenir l&rsquo;interior de l&rsquo;immoble a una temperatura de confort (entre 17 i 20&deg;C) durant els vuit mesos freds de l&rsquo;any (de gener a maig i d&rsquo;octubre a desembre). Aquest valor es classifica en 7 etiquetes ordenades de la &ldquo;A&rdquo; (situaci&oacute; m&eacute;s favorable) a la &ldquo;G&rdquo; (situaci&oacute; m&eacute;s desfavorable)</div>
                                    <img  slot="reference" style="width: 12px;margin-left: 5px;margin-top: 3px;" src="@/assets/info.svg"/>
                                </popper>
                            </div>
                        </div>
                    </div>
                    <div class="is-flex is-align-content-baseline puntejat">
                        <div>Qualificaci&oacute; estimada de la demanda de refrigeraci&oacute;</div>
                        <span class="is-flex-grow-1 is-flex-shrink-1"></span>
                        <div>
                            <span>{{ dadesEA.EF_EN_08 }}</span>
                            <div class="is-pulled-right">
                                <popper
                                    trigger="hover"
                                    :options="{
                                      placement: 'top',
                                      modifiers: { offset: { offset: '0,10px' } }
                                    }">
                                    <div class="popper">La qualificaci&oacute; de la demanda energ&egrave;tica de refrigeraci&oacute; &eacute;s un valor que indica la quantitat d&rsquo;energia necess&agrave;ria per mantenir l&rsquo;interior de l&rsquo;immoble a una temperatura de confort (entre 25 i 27&deg;C) durant els quatre mesos c&agrave;lids de l&rsquo;any (de juny a setembre). Aquest valor es classifica en 7 etiquetes ordenades de la &ldquo;A&rdquo; (situaci&oacute; m&eacute;s favorable) a la &ldquo;G&rdquo; (situaci&oacute; m&eacute;s desfavorable)</div>
                                    <img  slot="reference" style="width: 12px;margin-left: 5px;margin-top: 3px;" src="@/assets/info.svg"/>
                                </popper>
                            </div>
                        </div>
                    </div>
                    <div class="is-flex is-align-content-baseline puntejat">
                        <div>Programa convocat&ograve;ria Fons Next Generation</div>
                        <span class="is-flex-grow-1 is-flex-shrink-1"></span>
                        <div>{{ dadesEA.PROGRAMA }}</div>
                    </div>
                </div>
            </div>
            <div>(*) Els valors d&rsquo;aquesta taula provenen d&rsquo;una simulaci&oacute; energ&egrave;tica (resultats estimats) i, per tant, els valors d&rsquo;un Certificat d&rsquo;Efici&egrave;ncia Energ&egrave;tica (CEE) oficial sempre prevaldran</div>
        </section>
        <section class="tabbs">
            <div class="titolet">Vols con&egrave;ixer les possibilitats de millora del teu edifici?</div>
            <div>Per con&egrave;ixer les possibilitats de millora del teu edifici, aquest simulador et planteja a continuaci&oacute; un seguit de possibles actuacions de rehabilitaci&oacute;</div>
            <div class="tabs">
                <ul>
                    <li :class="{'is-active': actuacio==1}" @click="canviActuacio(1)"><a>Actuaci&oacute; d'a&iuml;llament integral</a></li>
                    <li :class="{'is-active': actuacio==2}" @click="canviActuacio(2)"><a>Actuaci&oacute; d'a&iuml;llament de fa&ccedil;anes</a></li>
                </ul>
            </div>
            <div>{{textActuacio}}</div>
        </section>
        <section>
            <div class="titolet">Quin impacte suposaria aquesta actuaci&oacute;?</div>
            <div>Tot seguit, aquesta aplicaci&oacute; et mostra una comparativa energ&egrave;tica entre l&rsquo;estat actual del teu edifici i l&rsquo;estat rehabilitat, aix&iacute; com dels estalvis estimats en q&uuml;esti&oacute; de demanda energ&egrave;tica, consum d&rsquo;energia i factura energ&egrave;tica despr&eacute;s de l&rsquo;actuaci&oacute; de rehabilitaci&oacute; seleccionada</div>
        </section>
        <section class="barraContainer">
            <div >Qualificaci&oacute; energ&egrave;tica estimada en emissions</div>
            <Barra :actual="barraActual" :proposat="barraProposat"></Barra>
        </section>
        <section class="gauge">
            <div class="columns">
                <div class="column is-one-third">
                    <div class="has-text-centered">Estalvi estimat en la demanda energ&egrave;tica</div>
                    <gauge :valor="gauges[0]"></gauge>
                </div>
                <div class="column is-one-third">
                    <div class="has-text-centered">Estalvi estimat en el consum d&rsquo;energia</div>
                    <gauge :valor="gauges[1]"></gauge>
                </div>
                <div class="column is-one-third">
                    <div class="has-text-centered">Estalvi estimat en la factura energ&egrave;tica</div>
                    <gauge  :valor="gauges[2]"></gauge>
                </div>
            </div>
        </section>
        <section class="tindria">
            <div class="titolet">Quin cost tindria i quina part podria ser subvencionable?</div>
            <div>A continuaci&oacute;, aquest simulador et presenta la inversi&oacute; econ&ograve;mica estimada de l&rsquo;actuaci&oacute; seleccionada i la part que podria ser subvencionada amb la convocat&ograve;ria d&rsquo;ajuts a la rehabilitaci&oacute; de barris i edificis a trav&eacute;s dels Fons Next Generation del Consorci de l&rsquo;Habitatge de Barcelona <a href="https://portaldogc.gencat.cat/utilsEADOP/PDF/8696/1915892.pdf" target="_blank">(consulta la convocat&ograve;ria en aquest enlla&ccedil;)</a>. Per poder sol&middot;licitar aquest ajut cal que l&rsquo;actuaci&oacute; de rehabilitaci&oacute; aconsegueixi com a m&iacute;nim una reducci&oacute; del 25 % de la demanda energ&egrave;tica i del 30 % del consum d&rsquo;energia.</div>
            <div v-if="subsidiGasPercen !== '0'" class="columns">
                <div class="column">
                    <div class="has-text-centered numero">{{ subsidiGasPercen }}%</div>
                    <div class="has-text-centered texte">Subvenci&oacute; m&agrave;xima de l&rsquo;actuaci&oacute; a trav&eacute;s dels Fons Next Generation</div>
                </div>
                <div class="column">
                    <div class="has-text-centered numero">{{ separadorMiles(topallGas) }}&euro;</div>
                    <div class="has-text-centered texte">Import m&agrave;xim de la subvenci&oacute; per edifici a trav&eacute;s els Fons Next Generation</div>
                </div>
            </div>
            <div v-else class="columns">
                <div class="column is-10 is-offset-1 has-text-centered verd">Les dades estimades d&rsquo;aquest simulador indiquen que l&rsquo;actuaci&oacute; seleccionada no compliria amb els requisits m&iacute;nims per optar a la convocat&ograve;ria d&rsquo;ajuts a trav&eacute;s dels Fons Next Generation. Tot i aix&ograve;, cal l&rsquo;assessorament d&rsquo;un t&egrave;cnic qualificat per verificar aquesta informaci&oacute;.
                    D&rsquo;altra banda, <strong>l&#39;Ajuntament de Barcelona compta amb altres convocat&ograve;ries d&rsquo;ajuts a la rehabilitaci&oacute; que poden ser viables pel teu edifici (<a href="https://www.habitatge.barcelona/ca/serveis-ajuts/rehabilitacio" target="_blank">consulta les convocat&ograve;ries en aquest enlla&ccedil;</a>)</strong>
                </div>
            </div>
            <table class="table is-fullwidth">
                <tbody>
                <tr>
                    <td>Inversi&oacute; inicial estimada de l&rsquo;actuaci&oacute;</td>
                    <td style="text-align: right">{{separadorMiles(taulaSubvencions[0])}}&euro;</td>
                </tr>
                <tr class="verd" style="font-weight: 600;">
                    <td>Part subvencionada a trav&eacute;s dels Fons Next Generation*</td>
                    <td style="text-align: right">{{separadorMiles(taulaSubvencions[1])}}&euro;</td>
                </tr>
                <tr style="font-weight: 600;">
                    <td>Inversi&oacute; final estimada de l&rsquo;actuaci&oacute;</td>
                    <td style="text-align: right">{{separadorMiles(taulaSubvencions[2])}}&euro;</td>
                </tr>
                <tr>
                    <td>Inversi&oacute; final estimada de l&#39;actuaci&oacute; per habitatge</td>
                    <td style="text-align: right">{{separadorMiles(taulaSubvencions[3])}}&euro;</td>
                </tr>
                </tbody>
            </table>
            <div>Nota: la inversi&oacute; estimada de l&rsquo;actuaci&oacute; inclou els costos directes (conjunt de processos d&rsquo;execuci&oacute;), els beneficis industrials, els honoraris dels t&egrave;cnics, l&rsquo;IVA i tota la resta de despeses generals i indirectes a tenir en compte.<br/>(*) La quantia m&agrave;xima de la part subvencionable es regir&agrave; pel percentatge m&agrave;xim de subvenci&oacute; perm&egrave;s segons la millora energ&egrave;tica (%) i el topall m&agrave;xim possible per habitatge multiplicat pel nombre d&rsquo;habitatges de l&rsquo;edifici (&euro;/edifici). En cas de justificar un estalvi energ&egrave;tic superior al 45 % la quantia m&agrave;xima podr&agrave; incrementar-se amb fons municipals
            </div>
        </section>
        <section class="quota">
            <div class="titolet">Quina seria la teva quota mensual estimada?</div>
            <div>A fi de facilitar el pagament del cost de les actuacions, els propietaris poden optar per demanar un cr&egrave;dit a les entitats financeres. Aquest simulador et presenta la quota mensual estimada en dos possibles escenaris de finan&ccedil;ament elaborats a partir de les condicions recollides en el conveni marc signat el 18 de febrer de 2022 entre l&rsquo;Ag&egrave;ncia de l&rsquo;Habitatge de Catalunya, Avalis de Catalunya SGR, l&rsquo;Institut Catal&agrave; de Finances i diverses entitats financeres <a href="https://presidencia.gencat.cat/ca/ambits_d_actuacio/relacions-institucionals/registre-de-convenis-de-collaboracio-i-cooperacio/detall#2022/8/0003" target="_blank">(consulta el conveni marc en aquest enllaç)</a></div>
            <div class="columns">
                <div class="column is-10 is-offset-1">
                    <table class="table is-fullwidth">
                        <thead>
                        <tr>
                            <td>A 15 anys (tipus d&rsquo;inter&egrave;s fix del 6 %)</td>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>Quota estimada per edifici</td>
                            <td style="text-align: right">{{separadorMiles(quotes[0])}}&nbsp;€/mes</td>
                        </tr>
                        <tr>
                            <td>Quota estimada per habitatge*</td>
                            <td style="text-align: right">{{separadorMiles(quotes[1])}}&nbsp;€/mes</td>
                        </tr>
                        </tbody>
                    </table>
                    <table class="table is-fullwidth" style="margin-top: 10px;">
                        <thead>
                        <tr>
                            <td>A 10 anys (tipus d&rsquo;inter&egrave;s fix del 5 %)</td>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>Quota estimada per edifici</td>
                            <td style="text-align: right">{{separadorMiles(quotes[2])}}&nbsp;€/mes</td>
                        </tr>
                        <tr>
                            <td>Quota estimada per habitatge*</td>
                            <td style="text-align: right">{{separadorMiles(quotes[3])}}&nbsp;€/mes</td>
                        </tr>
                        </tbody>
                    </table>
                    <!--<table class="table is-fullwidth">
                        <thead>
                        <tr>
                            <td>A 7 anys (tipus d&rsquo;inter&egrave;s fix del 4 %)</td>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>Quota estimada per comunitat de ve&iuml;ns</td>
                            <td style="text-align: right">{{separadorMiles(quotes[4])}}&nbsp;€/mes</td>
                        </tr>
                        <tr>
                            <td>Quota estimada per habitatge*</td>
                            <td style="text-align: right">{{separadorMiles(quotes[5])}}&nbsp;€/mes</td>
                        </tr>
                        </tbody>
                    </table> -->
                </div>
            </div>
            <div>(*) Aquest valor &eacute;s estimat i s&rsquo;ha calculat a partir del nombre d&rsquo;habitatges de l&rsquo;immoble disponibles al cadastre. Per a un resultat m&eacute;s acurat de la quota mensual per cada habitatge, caldr&agrave; tenir en compte la participaci&oacute; de cada propietari en la totalitat de l&rsquo;edifici</div>
        </section>
        <section>
            <div class="has-text-centered" style="font-size: 16px; font-weight: 600;">Per a m&eacute;s informaci&oacute;, pots contactar amb el teu administrador de finques o amb l&rsquo;Oficina de Rehabilitaci&oacute; Municipal de l&rsquo;Ajuntament de Barcelona a trav&eacute;s del correu ng.rehabilitacio@bcn.cat</div>
            <div style="margin:30px 30% 0 30%;" class="imprimirContainer"><button class="button is-large is-fullwidth" @click="print">Imprimir</button></div>
        </section>
        </div>
    </div>
</template>

<script>
import Axios from 'axios'
//import vSelect from 'vue-select'
import Popper from 'vue-popperjs'
import Barra from './components/Barra.vue'
import Gauge from './components/Gauge.vue'
import Formulari from './Formulari.vue'

export default {
    name: 'Consulta',
    components: {
        Axios,
        Formulari,
        Barra,
        Gauge,
        Popper,
    },
    directives:{},
    data: function(){
        return{
            refcatTrobada: '',
            dadesEA: [],
            actuacio: 1,
            isVisibleAvisNoDades: false,
            barraActual: null,
            barraProposat: null,
            gauges: [],
            subsidiGasPercen: '',
            topallGas: null,
            taulaSubvencions: [],
            quotes: [],
            text1:'Aquesta actuació contempla la millora de les prestacions de tota l’envolupant tèrmica de l’edifici. Consisteix en la substitució de les finestres existents per unes de major qualitat i l’aïllament integral de façanes (tant principals com posteriors), cobertes, mitgeres i patis interiors. Aquesta actuació es caracteritza per buscar la màxima millora energètica a través d’una intervenció global',
            text2: 'Aquesta actuació contempla la millora parcial de les prestacions de l’envolupant tèrmica de l’edifici. Consisteix en la substitució de finestres existents per unes de major qualitat i l’aïllament de façanes (tant principals com posteriors). Aquesta actuació, en comparació amb l’actuació d’aïllament integral, es caracteritza per unes millores energètiques més modestes, uns menors costos i una major velocitat d’execució de les obres'
        }
    },
    //props: [refcat],
    computed: {
        textActuacio(){
            return (this.actuacio == 1) ? this.text1 : this.text2;
        }
    },
    watch: {},
    methods: {
        async print () {
            // Pass the element id here
            await this.$htmlToPaper('app');
        },
        number_format (number, decimals, dec_point, thousands_sep) {
            // Strip all characters but numerical ones.
            number = (number + '').replace(/[^0-9+\-Ee.]/g, '');
            var n = !isFinite(+number) ? 0 : +number,
                prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
                sep = (typeof thousands_sep === 'undefined') ? ',' : thousands_sep,
                dec = (typeof dec_point === 'undefined') ? '.' : dec_point,
                s = '',
                toFixedFix = function (n, prec) {
                    var k = Math.pow(10, prec);
                    return '' + Math.round(n * k) / k;
                };
            // Fix for IE parseFloat(0.55).toFixed(0) = 0;
            s = (prec ? toFixedFix(n, prec) : '' + Math.round(n)).split('.');
            if (s[0].length > 3) {
                s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
            }
            if (s.length > 1 && (s[1] || '').length < prec) {
                s[1] = s[1] || '';
                s[1] += new Array(prec - s[1].length + 1).join('0');
            }
            return s.join(dec);
        },
        separadorMiles(val) {
            return (val != null) ? this.number_format(val, 2, ",", ".") : "0";
        },
        canviActuacio(act){
            this.actuacio = act;
            this.getActuacio();
        },
        getInfoByRefcat(refcatTrobada){
            this.isVisibleAvisNoDades = false;
            this.refcatTrobada = refcatTrobada;
            let self = this;
            Axios.get(process.env.VUE_APP_URL_PHP + 'AJAXgetInfoByRefcat.php?refcat=' + this.refcatTrobada)
                .then(function (response) {
                    console.log(response.data);
                    if (response.data.length == 0){
                        self.isVisibleAvisNoDades = true
                    } else {
                        self.dadesEA = response.data;
                        self.getActuacio();
                    }
                })
                .catch(function (error) {
                    console.log("ERROR", error);
                })
                .finally(function () {
                    console.log("ALWAYS");
                });
        },
        getActuacio(){
            let self = this;
            Axios.get(process.env.VUE_APP_URL_PHP + 'AJAXgetActuacio.php?refcat=' + this.refcatTrobada+'&actuacio='+this.actuacio)
                .then(function (response) {
                    console.log(response.data);
                    self.barraActual = response.data['GRAF_EA_GAS'];
                    self.barraProposat = response.data['GRAF_PI_GAS'];

                    self.gauges = [];
                    self.gauges.push([response.data['RED_DEM'] * 1]);
                    self.gauges.push([response.data['RED_CONS_GAS'] * 1]);
                    self.gauges.push([response.data['RED_FACT_GAS'] * 1]);

                    self.subsidiGasPercen = response.data['SUBSIDI_GAS_PERCEN'];
                    self.topallGas = response.data['TOPALL_GAS'];

                    self.taulaSubvencions = [];
                    self.taulaSubvencions.push(response.data['MP_EN_35']);
                    self.taulaSubvencions.push(response.data['INV_SUB_GAS']);
                    self.taulaSubvencions.push(response.data['INV_NO_SUB_GAS']);
                    self.taulaSubvencions.push(response.data['INV_SUB_GAS_HAB']);

                    self.quotes = [];
                    self.quotes.push(response.data['FIN_15_COM'] * 1);
                    self.quotes.push(response.data['FIN_15_HAB'] * 1);
                    self.quotes.push(response.data['FIN_10_COM'] * 1);
                    self.quotes.push(response.data['FIN_10_HAB'] * 1);
                    self.quotes.push(response.data['FIN_7_COM'] * 1);
                    self.quotes.push(response.data['FIN_7_HAB'] * 1);

                })
                .catch(function (error) {
                    console.log("ERROR", error);
                })
                .finally(function () {
                    console.log("ALWAYS");
                });
        },


    },
    created() {},
    destroyed() {},
    mounted: function () {
        console.log("HOLA CONSULTA!!");
        if(this.$route.params.refcat != ''){
            this.refcatTrobada = this.$route.params.refcat;
            this.getInfoByRefcat(this.refcatTrobada);
            this.getActuacio();
        }
    },
    beforeDestroy() {}
}
</script>


<style lang="scss">

@import 'vue-select/dist/vue-select.css';
@import 'vue-popperjs/dist/vue-popper.min.css';
@import './scss/mystyles.scss';

html {
    font-family: 'Montserrat', sans-serif;
    @include from($tablet) {
        //overflow-y: hidden !important;
    }
    scroll-behavior: smooth;
    font-size: 12px;
    .titolot {font-size: 32px; font-weight: 600;}

    body {
        background-color: $background;
    }

    .puntejat {
        border-bottom: 1px dotted #000;
        padding: 5px 0;
    }

    .popper {font-size: 0.9rem;}

    section {
        margin-top: 30px;


        .titolet {
            font-weight: 600;
            font-size: 14pt;
            margin-bottom: 20px;
        }
        .tabs{
            margin-top: 20px;
            font-size: 14px;
            font-weight: 600;
        }
        table.table {
            background-color: transparent;
            tr {
                border-bottom: 1px dotted #000;
                padding: 5px 0;

                td {border:none;}

                &.verd {
                    background-color: rgba(6, 145, 76, 0.1);
                    td {color:#06914C;}
                }
            }
        }
    }

    section.form {
        label {
            font-weight: 600;
            font-size: 14px;
        }
    }

    section.tabbs {
        .tabs li.is-active a {
            border-bottom-color: black;
            color: black;
        }
    }

    section.gauge {
        .has-text-centered {font-size: 12px;}
    }

    section.tindria {
        .columns {margin: 20px 0;}
        .numero {
            font-size: 32px;
            font-weight: 600;
            color: #06914C;
        }
        .texte {margin: 0 100px;}
        .verd {
            background-color: rgba(6, 145, 76, 0.1);
            color:#06914C;
            strong {color:inherit;}
        }
    }

    section.quota {
        .columns {margin-top: 20px;}
        table.table thead tr {
            font-weight: 600;
            border-bottom: solid;
        }
    }

    @include mobile {
        .tabs ul {
            flex-direction: column;

            li {
                width: 100%;
            }
        }
    }

    .notfound {
        span.correu{text-decoration: underline;}
        font-size: 16px;
    }
}
</style>

