<template>
    <div id="app">
        <router-view></router-view>
    </div>
</template>

<script>


export default {
    name: 'App',
    components: {},
    directives:{},
    data: function(){
        return{

        }
    },
    props: [],
    computed: {},
    watch: {},
    methods: {

    },
    created() {},
    destroyed() {},
    mounted: function () {
        console.log("HOLA APP!!");
        console.log(process.env.VUE_APP_URL_PHP);
    },
    beforeDestroy() {}
}
</script>


<style lang="scss">
@import './scss/mystyles.scss';

html {
    font-family: 'Montserrat', sans-serif;
    @include from($tablet) {
        //overflow-y: hidden !important;
    }
    scroll-behavior: smooth;
    font-size: 12px;
    background-color: $background;

    body {
        background-color: $background;
    }

}
</style>

