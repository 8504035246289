<template>
    <div class="home">
        <section>
            <figure class="image">
                <img style="width: auto;height: 47px;" src="./assets/header.png">
            </figure>
        </section>
        <section>
            <div class="titolot has-text-centered">Simulador de rehabilitaci&oacute;</div>
        </section>
        <section>
            <div style="font-size: 16px;font-weight: 600;margin-bottom: 20px;">Presentaci&oacute;</div>
        </section>
        <section style="margin-top: 0;">
            <div class="titolet">Qu&egrave; &eacute;s?</div>
            <div class="paragraf">Aquesta aplicaci&oacute; &eacute;s un espai de consulta obert al p&uacute;blic que ofereix informaci&oacute; clau sobre l&rsquo;estat actual dels edificis de la ciutat de Barcelona, el seu potencial de millora a trav&eacute;s d&rsquo;actuacions de rehabilitaci&oacute; energ&egrave;tica i la possibilitat d&rsquo;acollir-se a la subvenci&oacute; de les actuacions mitjan&ccedil;ant els Fons Next Generation.</div>
            <div class="titolet">Quina &eacute;s la seva finalitat?</div>
            <div class="paragraf">El seu principal objectiu &eacute;s fomentar la rehabilitaci&oacute; a la ciutat de Barcelona en el marc dels Fons Next Generation. Per fer-ho, ofereix informaci&oacute; sobre les diferents actuacions de rehabilitaci&oacute; energ&egrave;tica que es podrien dur a terme en els edificis, una estimaci&oacute; del seu cost i informaci&oacute; sobre el seu possible finan&ccedil;ament i subvenci&oacute;.</div>
            <div class="titolet">Quines s&oacute;n les seves caracter&iacute;stiques principals?</div>
            <div class="paragraf">Aquest simulador ofereix informaci&oacute; d’aproximadament 50.000 edificis d&rsquo;habitatges que representen un 80% del parc d&rsquo;&uacute;s residencial de la ciutat de Barcelona, donat que s&rsquo;ha optat per descartar immobles constru&iuml;ts posteriorment al 2008 per la seva qualitat energ&egrave;tica d&rsquo;obligat compliment normatiu.</div>
            <div class="paragraf">Les dades que aqu&iacute; es presenten provenen d&rsquo;una simulaci&oacute; energ&egrave;tica elaborada principalment a partir del cadastre i del Codi T&egrave;cnic de l&rsquo;Edificaci&oacute; (CTE), tant per l&rsquo;estat actual de l&rsquo;edifici com per les possibles millores a executar. Aix&iacute; doncs, tota la informaci&oacute; disponible en aquest portal &eacute;s estimada i orientativa.</div>
            <div class="paragraf">Cal tenir en compte que, per tal de disposar d&rsquo;estudis energ&egrave;tics m&eacute;s detallats i de car&agrave;cter oficial, adaptats a la realitat de cadascun dels edificis, cal l&rsquo;assessorament d&rsquo;un t&egrave;cnic qualificat (Col&middot;legi d&rsquo;Arquitectes de Catalunya i/o del Col&middot;legi d&rsquo;Aparelladors, Arquitectes T&egrave;cnics i Enginyers de l&rsquo;Edificaci&oacute; de Catalunya).</div>
        </section>
        <section>
            <div class="titolet"><a href="./Simulador_Metodologia.pdf" target="_blank">Consulta la metodologia detallada del simulador</a></div>
            <div class="titolet"><a href="./Simulador_Guia usuari.pdf" target="_blank">Consulta la guia de l&rsquo;usuari del simulador</a></div>
        </section>
        <section>
            <div style="font-size: 16px;font-weight: 600;">Consulta l’estat actual i les possibilitats de millora del teu edifici</div>
            <div>Pots seleccionar el teu edifici introduint la via i el n&uacute;mero del teu immoble o mitjan&ccedil;ant la seva refer&egrave;ncia cadastral</div>
        </section>
        <section class="form">
            <Formulari v-on:trobat="refcatTrobat"></Formulari>
        </section>
        <section>
            <div class="has-text-centered" style="font-size: 16px; font-weight: 600;">Per a m&eacute;s informaci&oacute;, pots contactar amb el teu administrador de finques o amb l&rsquo;Oficina de Rehabilitaci&oacute; Municipal de l&rsquo;Ajuntament de Barcelona a trav&eacute;s del correu ng.rehabilitacio@bcn.cat</div>
        </section>
    </div>
</template>

<script>

import Formulari from './Formulari.vue'

export default {
    name: "Presentacio",
    components: {
        Formulari
    },
    methods: {
        refcatTrobat(ref) {
            this.$router.push({name: 'consulta', params:{refcat: ref}});
        }
    }
}
</script>

<style lang="scss">

@import 'vue-select/dist/vue-select.css';
@import './scss/mystyles.scss';

html {
    font-family: 'Montserrat', sans-serif;
    @include from($tablet) {
        //overflow-y: hidden !important;
    }
    scroll-behavior: smooth;
    font-size: 12px;
    background-color: $background;

    body {
        background-color: $background;
    }

    .home {
        .titolet {font-size: 12px;}
        .paragraf {margin-bottom: 20px;}
        .titolot {font-size: 32px; font-weight: 600;}

        a {color: black; text-decoration: underline;}
    }
}

</style>

