<template>
    <section class="barra">
        <div class="columns is-mobile valor_actual">
            <div class="column" v-for="(value, key) in valors">
                <div v-if="key == actual">
                    <div class="text">Estat&nbsp;actual</div>
                    <div class="svgContainer">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 218.98 139"><g><path d="M130.36,130.36L210.37,50.35c11.48-11.49,11.48-30.2,0-41.69C204.8,3.08,197.39,.01,189.51,0c-7.88,0-15.29,3.06-20.87,8.63l-59.14,59.14L50.36,8.64C44.79,3.07,37.38,0,29.5,0,13.24,0,.01,13.22,0,29.48v.04c0,7.77,3.15,15.37,8.64,20.85l80,80c5.57,5.57,12.98,8.64,20.86,8.64s15.29-3.07,20.86-8.64Z"/></g></svg>
                    </div>
                </div>
            </div>
        </div>
        <div class="columns is-mobile">
            <div class="column" style="background-color:#E22A2E;">
                <div class="columns">
                    <div class="column"></div>
                    <div class="column" style="text-align: center;">G</div>
                    <div class="column"></div>
                </div>
            </div>
            <div class="column" style="background-color:#DB6D32;">
                <div class="columns">
                    <div class="column"></div>
                    <div class="column" style="text-align: center;">F</div>
                    <div class="column"></div>
                </div>
            </div>
            <div class="column" style="background-color:#E4B218;">
                <div class="columns">
                    <div class="column"></div>
                    <div class="column" style="text-align: center;">E</div>
                    <div class="column"></div>
                </div>
            </div>
            <div class="column" style="background-color:#E8E021;">
                <div class="columns">
                    <div class="column"></div>
                    <div class="column" style="text-align: center;">D</div>
                    <div class="column"></div>
                </div>
            </div>
            <div class="column" style="background-color:#97C637;">
                <div class="columns">
                    <div class="column"></div>
                    <div class="column" style="text-align: center;">C</div>
                    <div class="column"></div>
                </div>
            </div>
            <div class="column" style="background-color:#21AA45;">
                <div class="columns">
                    <div class="column"></div>
                    <div class="column" style="text-align: center;">B</div>
                    <div class="column"></div>
                </div>
            </div>
            <div class="column" style="background-color:#06914C;">
                <div class="columns">
                    <div class="column"></div>
                    <div class="column" style="text-align: center;">A</div>
                    <div class="column"></div>
                </div>
            </div>
        </div>
        <div class="columns is-mobile valor_proposat">
            <div class="column" style="padding-top: 0;padding-bottom: 0;" v-for="(value, key) in valors">
                <div v-if="key == proposat">
                    <div class="svgContainer">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 218.98 139"><g><path d="M88.62,8.64L8.61,88.65c-11.48,11.49-11.48,30.2,0,41.69,5.57,5.58,12.97,8.65,20.85,8.65,7.88,0,15.29-3.06,20.87-8.63l59.14-59.14,59.14,59.14c5.57,5.57,12.98,8.64,20.86,8.64,16.26,0,29.49-13.22,29.5-29.48v-.04c0-7.77-3.15-15.37-8.64-20.85L130.34,8.64C124.77,3.07,117.36,0,109.48,0s-15.29,3.07-20.86,8.64Z"/></g></svg>
                    </div>
                    <div class="text">Estat&nbsp;rehabilitat</div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: 'Barra',
    props: {
        actual: String,
        proposat: String,
    },
    data: function(){
        return{
            valors:
                {
                    "G3": 4.761904762,
                    "G2": 9.523809524,
                    "G1": 14.28571429,
                    "F3": 19.04761905,
                    "F2": 23.80952381,
                    "F1": 28.57142857,
                    "E3": 33.33333333,
                    "E2": 38.0952381,
                    "E1": 42.85714286,
                    "D3": 47.61904762,
                    "D2": 52.38095238,
                    "D1": 57.14285714,
                    "C3": 61.9047619,
                    "C2": 66.66666667,
                    "C1": 71.42857143,
                    "B3": 76.19047619,
                    "B2": 80.95238095,
                    "B1": 85.71428571,
                    "A3": 90.47619048,
                    "A2": 95.23809524,
                    "A1": 100
                },
            valorActual: null,
            valorProposat: null,
        }
    },
    watch: {
        /*actual(newVal, oldVal){
            console.log("CANVI DE PROP ACTUAL!!!", newVal);
            this.valorActual = this.getLetter(newVal);
            console.log("BARRA", this.valorActual);
        },
        proposat(newVal, oldVal){
            console.log("CANVI DE PROP PROPOSAT!!!", newVal);
            this.valorProposat = this.getLetter(newVal);
            console.log("BARRA", this.valorProposat);
        }*/
    },
    methods: {
        getLetter(value){
            let val = '';
            let self = this;
            for (const v in this.valors) {
                if(self.valors[v] > value && val === ''){
                    val = v;
                }
            }
            return val;
        }
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import 'vue-select/dist/vue-select.css';
@import './../scss/mystyles.scss';

    .barra {
        font-weight: 600;
        print-color-adjust: exact;

        .valor_actual, .valor_proposat {
            > div {
                position: relative;
                padding-top: 0;
                padding-bottom: 0;

                .svgContainer {
                    text-align: center;

                    svg {
                        width: 10px;
                        height: 10px;
                    }
                }
            }
        }
        .valor_actual{
            .text {
                position: absolute;
                top: -15px;
                left: -25%;
            }
            svg {fill:red;}

        }
        .valor_proposat{
            .text {
                position: absolute;
                top: 10px;
                left: -50%;
            }
            svg {fill:darkgreen;}
        }
    }
</style>
