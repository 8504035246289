<template>
    <section class="gauge">
        <div id="chart">
            <vue-apex-charts type="radialBar" :options="chartOptions" :series="valor"></vue-apex-charts>
        </div>
    </section>
</template>

<script>
    import VueApexCharts from 'vue-apexcharts'

export default {
    name: 'Gauge',
    props: {
        valor: {
            required: false,
            type: Array,
            default:()=> {return [0]}
        },
    },
    components: {
        VueApexCharts,
    },
    data: function(){
        return{
            series: [76],
            chartOptions: {
                chart: {
                    type: 'radialBar',
                    offsetY: -20,
                    sparkline: {
                        enabled: true
                    }
                },
                plotOptions: {
                    radialBar: {
                        startAngle: -90,
                        endAngle: 90,
                        track: {
                            background: "#e7e7e7",
                            strokeWidth: '97%',
                            margin: 5, // margin is in pixels
                            dropShadow: {
                                enabled: true,
                                top: 2,
                                left: 0,
                                color: '#999',
                                opacity: 1,
                                blur: 2
                            }
                        },
                        dataLabels: {
                            name: {
                                show: false
                            },
                            value: {
                                offsetY: -2,
                                fontSize: '22px',
                                fontFamily: 'Montserrat, sans-serif',
                            }
                        }
                    }
                },
                grid: {
                    padding: {
                        top: -10
                    }
                },
                fill: {
                    type: 'solid',
                    colors: ['#06914C'],

                },
                labels: ['Average Results'],
            },
        }
    },
    watch: {
    },
    computed:{},
    methods: {

    },
    mounted: function(){}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

@import './../scss/mystyles.scss';


</style>
