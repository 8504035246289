import Vue from 'vue'
import App from './App.vue'
import Presentacio from './Presentacio.vue'
import Consulta from './Consulta.vue'
import VueHtmlToPaper from 'vue-html-to-paper'
import VueRouter from 'vue-router'
//import HomeView from "@/views/HomeView";
//import router from './router'

const printOptions = {
  name: '_blank',
  specs: [
    'fullscreen=yes',
    'titlebar=yes',
    'scrollbars=yes'
  ],
  styles: [
    'https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css',
    'https://unpkg.com/kidlat-css/css/kidlat.css',
    'https://simulador.rehabilitacio.imhab.cat/css/print.css',
    'https://simulador.rehabilitacio.imhab.cat/css/app.css',
  ],
  timeout: 1000, // default timeout before the print window appears
  autoClose: true, // if false, the window will not close after printing
  windowTitle: window.document.title, // override the window title
}

Vue.use(VueRouter)
Vue.use(VueHtmlToPaper, printOptions);


Vue.config.productionTip = false

const routes = [
  {
    path: '/',
    name: 'home',
    component: Presentacio
  },
  {
    path: '/consulta/:refcat',
    name: 'consulta',
    props: true,
    component: Consulta,
  }
]

const router = new VueRouter({
 // mode: 'history',
  base: process.env.BASE_URL,
  routes: routes
})

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
